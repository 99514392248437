import React from "react";
import Header from "../components/Header";
import { useState, useEffect } from "react";
import ExamineeTable from "../components/ExamineeTable";
import CreateExamModal from "../components/CreateExamModal";
import { HiMiniPencilSquare } from "react-icons/hi2";
import axios from "axios";
import Snackbar from "../components/Snackbar";
import Cookies from 'js-cookie';

function TeacherDashboard() {

    const [exams, setExams] = useState([]);
    const [showModal, setShowModal] = useState(false); 
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [selectedExam, setSelectedExam] = useState(exams[0] ? exams[0] : null);  
   
    // RSV moved to top
    const email = Cookies.get('email'); 

    useEffect(() => {
        const accessToken = Cookies.get('accessToken');

        if (!accessToken) {
            window.location.href = '/login';
            return;
        }
        
        // RSV         
        console.log("email: ", email);
        console.log("accessToken: ", accessToken);

        if (email){
            const fetchTeacherData = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/email/${email}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    });
            
                console.log("Teacher Data", response.data);
                setSelectedTeacher(response.data._id);
                Cookies.set('userId', response.data._id);
                
                } catch (error) {
                    if (error.response) {
                        console.error("Response error:", error.response.data);
                        if (error.response.status === 401) {
                            Cookies.remove('token');
                            Cookies.remove('email');
                            window.location.href = '/login';
                        }
                    } else if (error.request) {
                        console.error("Request error:", error.request);
                        console.error("Request details:", {
                            readyState: error.request.readyState,
                            status: error.request.status,
                            statusText: error.request.statusText,
                            responseURL: error.request.responseURL,
                            responseText: error.request.responseText
                        });
                    }
                }
            }
            const user_role = Cookies.get('userRole');
            
            if (user_role === 'examinee' ) {    
                window.location.href = '/login          ';
                return;
            }

            fetchTeacherData();
        } 
    }, []);

    useEffect(() => {
        fetchExams();
    }, [selectedTeacher]);

    // fetch examinees when selected exam changes
    useEffect(() => {
        fetchExaminees(selectedExam);
    }, [selectedExam]);

    const fetchExams = () => {
        if (!selectedTeacher) return;
        const accessToken = Cookies.get('accessToken');
        console.log(process.env.REACT_APP_BACKEND_URL);

        // RSV added
        const moodleToken = Cookies.get('moodleToken');
        const moodleUserId = Cookies.get('moodleUserId');
        console.log("Moodle Token", moodleToken);
        console.log("Moodle User ID", moodleToken);  

        //Cookies.set('moodleToken', moodleToken, { sameSite: 'Lax', secure: true });
        //Cookies.set('moodleUserId', moodleUserId, { sameSite: 'Lax', secure: true });         

        // Prepare the params object conditionally
        //const params = (moodleToken && moodleUserId) ? { moodleToken, moodleUserId } : undefined;

        if (moodleToken !== 'null' && moodleUserId !== 'null') {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/teachers/${selectedTeacher}/exams/moodle`,{            
                headers: {
                    Authorization: `Bearer ${accessToken}`    
                    , 'Content-Type': 'application/json'    // RSV added
                }
                ,withCredentials: true // Include credentials (cookies)    
                ,params: { moodleToken, moodleUserId }           
                }
            )
                .then((res) => {
                    console.log("Moodle Response Data: ", res.data);                
                    
                    //const { moodle_token, moodle_user_id, teacher_exams, courses, moodle_exams } = res.data; // Destructure the response

                    // Handle teacher exams
                    //const teacher_exams = res.data.teacher_exams;
                    //const { moodle_token, moodle_user_id, moodle_courses, moodle_exams, existing_moodle_exams } = res.data;
                    const { moodle_token, moodle_user_id, moodle_courses, moodle_exams, new_exams } = res.data;
                    console.log("Moodle Token", moodle_token);
                    console.log("Moodle User ID", moodle_user_id);
                    console.log("Moodle Exams", moodle_exams);
                    console.log("Moodle Courses", moodle_courses);
                    console.log("New Exams", new_exams);
                    //console.log("Existing Moodle Exams", existing_moodle_exams);


                    //if (moodle_exams.length > 0) {
                        //const firstExam = teacher_exams[0];
                        //setSelectedExam(firstExam);
                        //console.log("First Teacher Exam", firstExam);
                        
                        //fetchExaminees(firstExam);
                    //}          

                })
                .catch((error) => {
                    console.error(error);                
                });
        }
        

        axios.get(`${process.env.REACT_APP_BACKEND_URL}/teachers/${selectedTeacher}/exams`,{            
            headers: {
                Authorization: `Bearer ${accessToken}`    
                //, 'Content-Type': 'application/json'    // RSV added
            }
            //,withCredentials: true // Include credentials (cookies)            
            }
        )
            .then((res) => {
                console.log("Response Data: ", res.data);
                
                // RSV revised
                /*setExams(res.data);
                if (res.data.length > 0) {
                    const firstExam = res.data[0];
                    setSelectedExam(firstExam);
                    console.log("First Exam", firstExam);
                    fetchExaminees(firstExam);
                }*/              

                // Handle teacher exams
                //const teacher_exams = res.data.teacher_exams;
                const { teacher_exams } = res.data;
                if (teacher_exams.length > 0) {
                    const firstExam = teacher_exams[0];
                    setSelectedExam(firstExam);
                    console.log("First Teacher Exam", firstExam);
                   
                    fetchExaminees(firstExam);
                }
            })
            .catch((error) => {
                console.error(error);                
            });
    };


    const [examinees, setExaminees] = useState([]);

    const fetchExaminees = (exam) => {
        const accessToken = Cookies.get('accessToken');
        if (!exam || !exam._id) return; // Guard clause to ensure exam is valid
        console.log("Fetching examinees for exam", exam._id);
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/exams/${exam._id}/examinees`,{
            headers: {
                Authorization: `Bearer ${accessToken}`       
            }
        })
        .then((res) => {
            console.log("Examinees", res.data);
            setExaminees(res.data);
        })
        .catch((error) => {
            console.error(error);
        });
    };

    // Function to handle exam selection
    const handleSelectExam = (exam) => {
        setSelectedExam(exam);
        setDropdownOpen(false); // Close the dropdown after selecting an exam
    };

    const handleCreateNewExam = () => {
        setSelectedExam(null);
        setShowModal(true); // Show the modal when "Create New Exam" is selected
    };

    

    const handleEditExam = () => {
        setShowModal(true);
    }

    // RSV - Added for Moodle integration
    const isTeacher = Cookies.get('isTeacher') === 'true'
    const isAdmin = Cookies.get('isAdmin') === 'true'
    const isStudent = Cookies.get('isStudent') === 'true'
    console.log("isTeacher: ", isTeacher);
    console.log("isAdmin: ", isAdmin);
    console.log("isStudent: ", isStudent);
    // RSV -------------------------------------

    return (
        <div className=" h-full min-h-screen w-full bg-body">
            <Header isTeacher={isTeacher} isAdmin={isAdmin} isStudent={isStudent} currentPage={'teacher-dashboard'} email={email}/>
            <div className="body flex flex-col w-screen h-full py-16 align-middle justify-center items-center space-y-6">
                <h1 className="flex w-full text-2xl font-bold text-maroon justify-center">
                    Teacher's Dashboard
                </h1>
                <div className="flex justify-center items-center">
                    <div className="relative">
                            <button
                                id="dropdown-button"
                                type="button"
                                className="py-3 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg bg-maroon text-white hover:brightness-90 font-semibold"
                                onClick={() => setDropdownOpen(!dropdownOpen)} // Toggle dropdown visibility
                                disabled={!selectedExam}
                            >
                                {selectedExam ? selectedExam.title : 'Select Exam'}
                                {/* Dropdown Arrow Icon */}
                            </button>
                            {dropdownOpen && (
                                <div className="absolute left-0 mt-2 min-w-60 bg-white shadow-md rounded-lg p-2 z-50">
                                    {exams.length > 0 && exams.sort((a, b) => a.title.localeCompare(b.title)).map((exam) => (
                                        <a
                                            key={exam._id}
                                            className="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                                            href="#"
                                            onClick={() => handleSelectExam(exam)}
                                        >
                                            {exam.title}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                    {selectedExam && 
                        <button onClick={handleEditExam} className="ml-4 inline-flex justify-center items-center w-10 h-10 bg-maroon text-white rounded-lg hover:brightness-90 font-semibold">
                            <HiMiniPencilSquare />
                        </button>
                    }
                    <button onClick={handleCreateNewExam} className="ml-4 inline-flex justify-center items-center w-10 h-10 bg-maroon text-white rounded-lg hover:brightness-90 font-semibold">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                        </svg>
                    </button>
                    {selectedExam ? <button className="py-3 px-4 ml-5 inline-flex items-center gap-x-2 text-sm rounded-lg bg-maroon text-white hover:brightness-90 font-semibold"
                        onClick={() => {
                            if (navigator.clipboard) {
                                navigator.clipboard.writeText(selectedExam.exam_code)
                                .then(() => {
                                    setSnackbarMessage("Copied to clipboard");
                                })
                                .catch(err => {
                                    console.error("Failed to copy text: ", err);
                                });
                            } else { // Fallback for browsers where navigator.clipboard is not available
                                // Create a temporary text area element
                                const textArea = document.createElement("textarea");
                                textArea.value = selectedExam.exam_code;
                                document.body.appendChild(textArea);
                                textArea.focus();
                                textArea.select();
                                try {
                                    // Attempt to execute the copy command
                                    const successful = document.execCommand('copy');
                                    const msg = successful ? 'Copied to clipboard' : 'Failed to copy';
                                    setSnackbarMessage(msg);
                                } catch (err) {
                                    console.error("Fallback: Oops, unable to copy", err);
                                }
                                // Cleanup by removing the temporary element
                                document.body.removeChild(textArea);
                            }
                        }}>
                            {selectedExam.exam_code}
                        
                    </button> : ""
                    }
                        
                </div>
                <ExamineeTable examinees={examinees} className="z-10"/>
            </div>
            {showModal && <CreateExamModal 
                onClose={() => {setShowModal(false); if(selectedExam == null){setSelectedExam(exams[0])}; fetchExams()}} 
                selectedExam={selectedExam} 
                selectedTeacher={selectedTeacher}
                setSnackbarMessage={setSnackbarMessage}
            />}
            {snackbarMessage && <Snackbar message={snackbarMessage} onClose={() => setSnackbarMessage('')} />}

        </div>
    );
}

export default TeacherDashboard;