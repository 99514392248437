import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import GoogleLoginPage from "./pages/GoogleLogin";
import SignUp from "./pages/SignUp";
import StudentDashboard from "./pages/StudentDashboard";
import TeacherDashboard from "./pages/TeacherDashboard";
import Analytics from "./pages/Analytics";
import Users from "./pages/Users";
import ExamProper from "./pages/ExamProper";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// RSV added to fix toxen issue
import '@preline/accordion';

import { IStaticMethods } from "preline/preline";
import { googleLogout } from "@react-oauth/google";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/student-dashboard" element={<StudentDashboard />} />
        <Route path="/teacher-dashboard" element={<TeacherDashboard />} />
        <Route path="/analytics/:result_id" element={<Analytics />} />
        <Route path="/users" element={<Users />} />
        <Route path="/exam-proper/:result_id" element={<ExamProper />} />
      </Routes>
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;