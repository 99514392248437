import React, { useState, useEffect } from 'react';
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import InputField from '../components/InputField'; 
import Modal from '../components/Modal';
// import IsLoading from './IsLoading';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  // RSV added for Moodle integration
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    // RSV clear cookies
    Cookies.remove('accessToken');
    Cookies.remove('userRole');
    Cookies.remove('userId');
    Cookies.remove('userStatus');
    Cookies.remove('email');

    // RSV Added
    Cookies.remove('moodleUserId');
    Cookies.remove('moodleToken');
    Cookies.remove('isAdmin');
    Cookies.remove('isTeacher');
    Cookies.remove('isStudent');

    // RSV commented out
    // Check if the user is already logged in
    /*const accessToken = Cookies.get('accessToken');
    const userRole = Cookies.get('userRole');
    const userId = Cookies.get('userId');
    const userStatus = Cookies.get('userStatus');*/

    // RSV added to debug
    /*const email = Cookies.get('email'); */

    console.log("Client ID", process.env.REACT_APP_GOOGLE_CLIENT_ID);    
    console.log("Backend URL", process.env.REACT_APP_BACKEND_URL); 

    // RSV commented out
    /*console.log('Cookies set:', {
      accessToken,
      userRole,
      userId,
      userStatus,
      email                 // RSV added
    });*/

    // RSV removed as this is unnecessary
    /*if (userStatus === 'accepted') {
      if (accessToken && userRole && userId) {
        console.log("role is", userRole);
        // RSV - fixed userRole to examinee
        //if (userRole === 'student') {
        if (userRole === 'examinee') {          
          window.location.href = '/student-dashboard';   
        } else if (userRole === 'teacher' || userRole === 'admin') {          
          window.location.href = '/teacher-dashboard';
        }
      }
    } */

    // RSV Added this function to test CORS
    /*const testCORS = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/test-cors`);
        console.log('CORS test response:', response.data);
      } catch (error) {
        console.error('CORS test error:', error);
      }
    }; */

    // RSV Added this to useEffect to test when the component loads
    // testCORS(); // Test CORS configuration
  }, []);

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
        const decodedToken = jwtDecode(credentialResponse?.credential);
        console.log("decodedToken: ", decodedToken);
        Cookies.set("email", decodedToken.email);

        console.log("process.env.REACT_APP_ORIGIN_URL: ", process.env.REACT_APP_ORIGIN_URL);

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login-google`, {
                  token: credentialResponse.credential
        },{ withCredentials: true });    
        
        // RSV for Moodle integration  -- For debugging
        //console.log(response);
        console.log("Login Google response:", response);
        /*const moodleUser = await fetchMoodleUser(decodedToken.email);
        // Create user in Moodle if not exists
        if (!moodleUser) {          
          const createdUsers = await createMoodleUser(decodedToken);
          moodleUser = createdUsers[0];
        } */
        
        if (response.data.newUser) {
          // RSV Revised to add picture
          // navigate(`/signup?email=${decodedToken.email}&firstName=${decodedToken.given_name}&lastName=${decodedToken.family_name}`);              
          navigate(`/signup?email=${decodedToken.email}&firstName=${decodedToken.given_name}&lastName=${decodedToken.family_name}&imagePreviewUrl=${decodedToken.picture}`);
        } else {
          Cookies.set("accessToken", response.data.token);    
          Cookies.set("userRole", response.data.user_role);
          Cookies.set("userStatus", response.data.user_status);

          // RSV added
          Cookies.set('userId', response.data.user_id);          
          //Cookies.set('accessToken', response.data.access_token);
          //Cookies.set('email', response.data.email);          

          if (response.data.user_status === 'accepted') {
            if (response.data.user_role === 'teacher') {
              console.log('Redirecting to teacher dashboard', response.data.user_role);
              navigate("/teacher-dashboard");
            } 
            else if (response.data.user_role === 'admin') {
              console.log('Redirecting to admin dashboard', response.data.user_role);
              navigate("/users");
            }
            else if (response.data.user_role === 'examinee') {
              console.log('Redirecting to examinee dashboard', response.data.user_role);
              navigate("/student-dashboard");
            }
          } else {
            setShowModal(true);
          }
        }
    } catch (error) { 
        console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    // Step 2: Check if fields are filled
    if (!username || !password) {
      setError('Both username and password are required.'); // Set error message
      return; // Stop the function from proceeding further
    }
    setError(''); // Clear any previous errors if the form is correctly filled

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, {
          username, 
          password
      });

      // RSV uncommented out
      console.log("Login response:", response.data);

      console.log("Login response:", {
        accessToken: response.data.access_token,
        userRole: response.data.user_role,
        userId: response.data.user_id,
        userStatus: response.data.user_status
      });

      if (response.data.access_token && response.data.user_role && response.data.user_id && response.data.user_status) {
        Cookies.set('accessToken', response.data.access_token);
        Cookies.set('userRole', response.data.user_role);
        Cookies.set('userId', response.data.user_id);
        Cookies.set('email', response.data.email);
        Cookies.set('userStatus', response.data.user_status);

        // RSV added
        Cookies.set('isAdmin', response.data.is_admin);
      }

      console.log('Cookies set:', {
        accessToken: Cookies.get('accessToken'),
        userRole: Cookies.get('userRole'),
        userId: Cookies.get('userId'),
        userStatus: Cookies.get('userStatus')
      });

      if (response.data.user_status === 'accepted') {
        if (response.data.user_role === 'teacher') {
          console.log('Redirecting to teacher dashboard', response.data.user_role);
          navigate("/teacher-dashboard");
        } 
        else if (response.data.user_role === 'admin') {
          console.log('Redirecting to admin dashboard', response.data.user_role);
          navigate("/users");
        } 
        else if (response.data.user_role === 'examinee') {
          console.log('Redirecting to student dashboard');
          window.location.href = '/student-dashboard';
        } else {
          console.log("role is", response.data.user_role);
        }
      } else {
        console.log("User status is", response.data.user_status);
        setShowModal(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Clear the cookies if the login is invalid
        Cookies.remove('accessToken');
        Cookies.remove('userRole');
        Cookies.remove('userId');
        Cookies.remove('userStatus');

        setError(error.response?.data?.error || 'Invalid username or password.');
      } else {
      setError(error.response?.data?.error || 'An error occurred.');
      }
    }
  }; 

  const handleCloseModal = () => {
    setShowModal(false);
    Cookies.remove('accessToken');
    Cookies.remove('userRole');
    Cookies.remove('userId');
    Cookies.remove('userStatus');

    // RSV Added
    Cookies.remove('moodleUserId');
    Cookies.remove('moodleToken');
    Cookies.remove('isAdmin');
    Cookies.remove('isTeacher');
    Cookies.remove('isStudent');
  }

  // RSV Added for Moodle Integration to replace handleSubmit
  // --------  
  const handleSubmitMoodle = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    // Step 2: Check if fields are filled
    if (!username || !password) {
      setError('Both username and password are required.'); // Set error message
      return; // Stop the function from proceeding further
    }
    setError(''); // Clear any previous errors if the form is correctly filled

    console.log("username: ", username);
    console.log("password: ", password);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, {
          username, 
          password        
      });

      console.log('Call backend API login');      

      // RSV uncommented out
      console.log("Login response:", response.data);
      console.log("Login response:", {
        accessToken: response.data.access_token,
        userRole: response.data.user_role,
        userId: response.data.user_id,
        userStatus: response.data.user_status,
        moodleUser: response.data.moodle_user,
        moodleRoles: response.data.moodle_roles,
        moodleUserRoles: response.data.moodle_user_roles
      });

      // RSV added
      Cookies.set('moodleUserId', response.data.moodle_user_id);
      Cookies.set('moodleToken', response.data.moodle_token);
      //if (response.data.moodle_user) {
      //  Cookies.set('moodleUserId', response.data.moodle_user_id.id);
      //  Cookies.set('moodleToken', response.data.moodle_user.token);
      //}
      Cookies.set('isTeacher', false);
      Cookies.set('isAdmin', false);
      Cookies.set('isStudent', false);
      if ((response.data.is_teacher) || (response.data.user_role === 'teacher')) {
        Cookies.set('isTeacher', true);
      }
      if ((response.data.is_admin) || (response.data.user_role === 'admin')) {
        Cookies.set('isAdmin', true);
      }
      if ((response.data.is_student) || (response.data.user_role === 'examinee')) {
        Cookies.set('isStudent', true);
      }

      if (response.data.access_token && response.data.user_role && response.data.user_id && response.data.user_status )        
      {
        Cookies.set('accessToken', response.data.access_token);
        Cookies.set('userRole', response.data.user_role);
        Cookies.set('userId', response.data.user_id);
        Cookies.set('email', response.data.email);
        Cookies.set('userStatus', response.data.user_status);        
      }

      console.log('Cookies set:', {
        accessToken: Cookies.get('accessToken'),
        userRole: Cookies.get('userRole'),
        userId: Cookies.get('userId'),
        userStatus: Cookies.get('userStatus'),
        moodleUserId: Cookies.get('moodleUserId'),
        moodleToken: Cookies.get('moodleToken'),
        isAdmin: Cookies.get('isAdmin'),
        isTeacher: Cookies.get('isTeacher'),
        isStudent: Cookies.get('isStudent')
      });

      if (response.data.user_status === 'accepted') {        
        if ((response.data.is_teacher) || (response.data.user_role === 'teacher')) {
          console.log('Redirecting to teacher dashboard', response.data.user_role);
          navigate("/teacher-dashboard");
        } else if ((response.data.is_admin) || (response.data.user_role === 'admin')) {
          console.log('Redirecting to admin dashboard', response.data.user_role);
          navigate("/users");
        } else if ((response.data.is_student) || (response.data.user_role === 'examinee')) {
          console.log('Redirecting to student dashboard');
          navigate('/student-dashboard');
        } else {
          console.log("role is", response.data.user_role);
        }
      } else {
          console.log("User status is", response.data.user_status);        
          if (response.data.newUser) {     
            setShowModal(false);    
            try {
              if (response.data.moodle_user) {
                console.log('Moodle user data received:', response.data.moodle_user);
                Cookies.set("username", username);

                // Cookies.set('accessToken', response.data.access_token);
                // Cookies.set('userRole', response.data.user_role);
                // Cookies.set('userId', response.data.user_id);
                Cookies.set('email', response.data.moodle_user.email);
                Cookies.set('firstName', response.data.moodle_user.firstName);
                Cookies.set('lastName', response.data.moodle_user.lastName);
                Cookies.set('imagePreviewUrl', response.data.moodle_user.profileimageurl);
                Cookies.set('isMoodle', response.data.is_moodle);
                Cookies.set('moodleUserId', response.data.moodle_user.id);
                Cookies.set('moodleIdNumber', response.data.moodle_user.idnumber);                
                // Cookies.set('userStatus', response.data.user_status);

                // RSV to set moodle flag
                //navigate(`/signup?email=${response.data.moodleUser.email}&username=${username}&firstName=${response.data.moodleUser.firstname}&lastName=${response.data.moodleUser.lastname}&imagePreviewUrl=${response.data.moodleUser.profileimageurl}`);
                navigate(`/signup?email=${response.data.moodle_user.email}&firstName=${response.data.moodle_user.firstname}&lastName=${response.data.moodle_user.lastname}`);
              } else {
                console.log('No Moodle user data received');
                setError('User not found in Moodle');
              }
            } catch (error) {
              console.error('Error processing Moodle user:', error);
              setError('Error processing user data');
            }
          } else {
            setShowModal(true);
          }
      }  

    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Clear the cookies if the login is invalid
        Cookies.remove('accessToken');
        Cookies.remove('userRole');
        Cookies.remove('userId');
        Cookies.remove('userStatus');
        setError(error.response?.data?.error || 'Invalid username or password.');            
      //} else if (error.message === 'User not found') {
      //   console.error("Caught an error:", error.message);
      //   setError(error.response?.data?.error || error.message); 
      } else {
        console.log("Login Error: ", error);
        setError(error.response?.data?.error || 'An error occurred.');
      }
    }
  };    

  // RSV added for Moodle integration - no longer necessary since moved to backend
  /*const validateMoodleUser = async (username) => {
    try {
      console.log("validateMoodleUser: ", username);
      console.log("REACT_APP_BACKEND_URL: ", process.env.REACT_APP_BACKEND_URL);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/validate-user`, { username });
      console.log("Valid Moodle User: ", response.data.isValid);
      return response.data.isValid;           // Assuming backend sends { isValid: true/false }
    } catch (error) {
         console.error('Error validating user:', error);
      return false;
    }
  };*/

  
  /* const fetchMoodleUser = async (username) => {
    try {
      console.log("fetchMoodleUser: ", username);
      console.log("REACT_APP_BACKEND_URL: ", process.env.REACT_APP_BACKEND_URL);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/fetch-user`, { username });
      console.log("Moodle User Data: ", response.data[0]);
      if (response.data.length > 0) {
        setUserDetails(response.data[0]);
      }
    } catch (error) {
         console.error('Error fetching user data:', error);      
    }
  };
             
  const fetchMoodleUserDetails = async (username) => {
      const token = process.env.MOODLE_TOKEN;   
      const url = '${process.env.MOODLE_URL}/webservice/rest/server.php';
      const params = {
          wstoken: token,
          wsfunction: 'core_user_get_users',
          moodlewsrestformat: 'json',
          criteria: [
              { key: 'email', value: 'user@example.com' }
          ],
      };
  
      try {
          const response = await axios.post(url, null, { params });
          console.log(response.data);
      } catch (error) {
          console.error('Error fetching Moodle data:', error);
      }*/
  //}; 
  
  //--------------------

  return (
    <div className="flex flex-col min-h-screen w-screen align-middle justify-center items-center bg-body"> 
        <h1 className="text-3xl font-bold text-center text-maroon mb-6">Specialized Online Assessment Platform - Prototype</h1>

        {/* Step 3: Display the error message if it exists */}
        {error && <div className="text-red-500 text-center mb-4">{error}</div>}

        {/*<form className="flex flex-col mt-8 space-y-8 w-1/3" style={{ maxWidth: "590px", minWidth: "300px" }} onSubmit={handleSubmit}>*/}
        {/*<form className="flex flex-col mt-8 space-y-8 w-1/3" style={{ maxWidth: "590px", minWidth: "300px" }} onSubmit={handleSubmitMoodle}>*/}
        {/* RSV for moodle integration */}
        <form className="flex flex-col mt-8 space-y-8 w-1/3" style={{ maxWidth: "590px", minWidth: "300px" }} onSubmit={handleSubmitMoodle}>
          <div className="flex flex-col space-y-1 w-full">
            <InputField type="text" label="Username" value={username} onChange={e => setUsername(e.target.value)} placeholder="e.g. juandelacruz"/>
            <InputField type="password" label="Password" value={password} onChange={e => setPassword(e.target.value)} placeholder="********" />
          </div>          
          <div className='flex w-full justify-center align-middle'>
            <button type="submit" className="group relative justify-center py-3 px-6 border border-transparent text-sm font-medium rounded-md text-white bg-maroon hover:bg-red-950 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Login
            </button>
          </div>
          <div className="flex w-full justify-center align-middle mt-4">
            <span>
              <GoogleLogin                
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}          
                onSuccess={handleGoogleLoginSuccess}
                onError={() => {
                console.error('Login failed');
                }}
              />
            </span>
          </div>
          <div className="flex text-sm items-center justify-center align-middle">
            <a href="/signup" className="font-medium text-maroon hover:text-indigo-500">
              Create an account
            </a>
          </div>
        </form>
        {showModal && (
        <Modal
          onClose={handleCloseModal}
          closeText="Close"
        >
          <div className="flex flex-col items-center justify-center p-4">
            <p>Your account is not yet verified by the admin.</p>
          </div>
        </Modal>
      )}  
    </div>
  );
}

axios.interceptors.request.use( function(config) {
  const accessToken = Cookies.get('accessToken');
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
}, function(error) {
  return Promise.reject(error);
});

export default LoginPage;
