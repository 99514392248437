import React from 'react';
import Cookies from 'js-cookie';

// RSV Added handling of various roles
const Header = ({ isTeacher, isAdmin, isStudent, currentPage, isLogoutBlocked, email }) => {

    const handleLogout = () => {
        // Add logout functionality here
        // Clear all cookies
        Cookies.remove('accessToken');
        Cookies.remove('userRole');
        Cookies.remove('userId');
        Cookies.remove('email');
        Cookies.remove('token');                 

        // RSV Added        
        Cookies.remove('userStatus');        
        Cookies.remove('moodleUserId');
        Cookies.remove('isTeacher');
        Cookies.remove('isAdmin');
        Cookies.remove('isStudent'); 
        
        // Redirect to login page
        window.location.href = '/login';
    }


  return (
    <div className="flex justify-between items-center w-full h-1/2 bg-maroon px-4" style={{ height: '58px', position:'sticky', top: 0, zIndex:1000 }}>
      <div className="flex">
        <h1 className="text-xl font-bold text-body">Soap Prototype</h1>
        <div className="flex text-white font-semibold mx-6 mt-0.5 space-x-6">
            {isTeacher && <div className='flex flex-col'>
              <a href="/teacher-dashboard"> Teacher's Dashboard </a>
              {currentPage=='teacher-dashboard' && 
                <hr className='bg-white h-0.5 rounded-lg'/>
              }
            </div>}     
            {isAdmin && <div className='flex flex-col'>
              <a href="/users"> Admin's Dashboard </a>
              {currentPage=='users' && 
                <hr className='bg-white h-0.5 rounded-lg'/>
              }
            </div>}    
            {isStudent && <div className='flex flex-col'>
              <a href="/student-dashboard"> Student's Dashboard </a>
              {currentPage=='student-dashboard' && 
                <hr className='bg-white h-0.5 rounded-lg'/>
              }
            </div>}             
          </div>             
      </div>
      <div className="flex items-center">
        {email && <span className="text-white mr-4">{email}</span>}
        {!isLogoutBlocked && <button onClick={handleLogout} className="py-0.5 rounded-md px-3 bg-white text-maroon border text-sm font-semibold border-maroon hover:bg-gray-200">
          Logout
        </button>}
      </div>
    </div>
  );
};

export default Header;